import React from 'react';
import { Link } from 'gatsby';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import DictLink from './DictLink';

const faqs = [
  {
    question: <>
      Why a Japanese-English picture dictionary?
    </>,
    answer: <>
      When learning Japanese, you&apos;ll be looking up words constantly.
      Some words lend themselves very well to visual presentation and that&apos;s why
      I created a picture dictionary for Japanese!
      With it, you can search for words in English (<DictLink search="hello" />),
      Japanese (<DictLink search="こんにちは" />) and romaji (<DictLink search="konnichiwa" />).
      The picture dictionary has 180,000+ definitions, 4,000+ example sentences
      and 4,000+ words with pictures.
    </>,
    tags: ['general'],
  }, {
    question: <>
      What about audial and kinaesthesic learning?
    </>,
    answer: <>
      Of course, visual learning is only one aspect of many.
      Audial and kinaesthesic learning will add depth and variety
      where it&apos;s more appropriate.
      I started with the <DictLink>picture dictionary</DictLink>,
      as I believe there aren&apos;t enough good
      visual resources out there.
      Also, Kuma Learn has plans to offer games in the future!
    </>,
    tags: ['general'],
  }, {
    question: <>
      What is SRS and how does it work?
    </>,
    answer: <>
      <p>
        SRS is short for Spaced Repeititon System. It&apos;s a flashcard system
        for you to learn vocabulary and kanji efficiently. You will be shown
        words that you don&apos;t know more often than words you know well.
        Studies have shown that this improves your learning and even changes how
        your brain is wired.
        [<a className="underline text-gray-50" href="https://www.leonardoenglish.com/blog/spaced-repetition" target="_blank" rel="noreferrer">1</a>]
      </p>
      <p className="mt-1">
        It works like this:
      </p>
      <ul className="list-disc list-inside">
        <li>
          You&apos;re shown a flashcard and you&apos;ll rate
          how well you know the word or kanji ("Perfect", "Familiar", "Total black out", etc).
        </li>
        <li>
          The system will learn how well you&apos;ve remembered a flashcard and,
          over time, will smartly ask you to review flashcards you know less well.
        </li>
      </ul>
      <p className="mt-1">
        The smart system is driven by an algorithm called SM-2.
        When you&apos;re ready to start, head on over to Kuma Learn&apos;s <Link href="https://app.kumalearn.com/srs?utm_medium=web&utm_source=www&utm_campaign=faqs" className="underline text-gray-50">Spaced Repetition System (SRS)</Link>.
      </p>
    </>,
    tags: ['general', 'srs'],
  }, {
    question: <>
      How often should I do spaced reptition?
    </>,
    answer: <>
      <p>
        There are different opinions on this but generally you want to increase
        your intervals between each review. One resource [<a className="underline text-gray-50" href="https://thinkhumm.com/blog/humms-guide-to-spaced-repetition-learning" target="_blank" rel="noreferrer">2</a>]
        suggests the ideal review schedule is 1 day, 7 days, 16 days
        and 35 days (and continue at 35 days). So you should review any new
        flashcard at this schedule.
      </p>
    </>,
    tags: ['general', 'srs'],
  }, {
    question: <>
      What is JLPT?
    </>,
    answer: <>
      <div>
        JLPT is short for the Japanese-Language Proficiency Test.
        It&apos;s a standardised test to evaluate and certify your
        proficiency in the Japanese language.
      </div>
      <div className="mt-1">
        It tests language knowledge,
        reading ability and listening ability, and is often used by
        companies to evaluate your lanugage ability if you&apos;re a
        non-native speaker.
      </div>
      <div className="mt-1">
        JLPT has five levels: N5 is the beginner level
        and the difficulty increases as you approach N1.
      </div>
    </>,
    tags: ['general', 'srs'],
  }, {
    question: <>
      What is my JLPT level?
    </>,
    answer: <>
      <div>
        There are various aspects to JLPT (reading, listening, etc), so
        your level may be higher in some areas than others.
        You can check your JLPT level with various tools online —
        search for "JLPT level check" on Google.
      </div>
    </>,
    tags: ['general', 'srs'],
  }, {
    question: <>
      Is it necessary to learn writing before starting to learn the language?
    </>,
    answer: <>
      <div>
        Japanese has three alphabets: <em>hiragana</em> ひらがな, <em>katakana</em> カタカナ
        (~50 characters each) and <em>kanji</em> 漢字 (2,000+ characters).
        You can <Link href="https://blog.kumalearn.com/hiragana-and-katakana-mnemonics?utm_medium=web&utm_source=www&utm_campaign=faqs">read an overview here</Link>.
      </div>
      <div className="mt-1">
        You often start learning hiragana and katakana because they&apos;re the
        basic (phonetic) alphabets that allow you start reading beginners&apos; materials.
        Because they&apos;re phonetic, they&apos;re also used to annotate <em>kanji</em>,
        so you might see small hiragana written above kanji to help the reader
        ("furigana", which <Link className="underline text-gray-50" to="/ebooks">Kuma Learn eBooks</Link> also do).
      </div>
    </>,
    tags: ['general'],
  }, {
    question: <>
      What&apos;s an effective approach to learning <em>kanji</em> 漢字?
    </>,
    answer: <>
      <div>
        Once you&apos;ve mastered hiragana and katakana (ひらがな and カタカナ), you can start learning
        with beginner resources written in Japanese.
        But you&apos;ll soon start to see lots of kanji (漢字).
      </div>
      <div className="mt-1">
        <em>Tip #1</em>: there are a couple of tricks: (a)
        learn "radicals" — components of kanji (e.g. 日 in 明) — rather than trying to
        remember each individual stroke of every kanji and (b) create
        "stories" for kanji. Stories work because kanji is logographic and human beings
        tend to remember stories well.
        A popular method for (b) is called "Heisig&apos;s Remembering the Kanji" (RTK).
      </div>
      <div className="mt-1">
        <em>Tip #2</em>: it&apos;s easier to learn kanji
        when they&apos;re read/written in context.
        For example, you&apos;ll learn crime kanji (e.g. <em>detective</em> 探偵,
        tantei) when you read crime novels or manga such as Detective Conan.
        Of course, you can also use the <DictLink>Japanese-English picture dictionary</DictLink>, to
        learn kanji with pictures.
      </div>
      <div className="mt-1">
        <em>Tip #3</em>: use efficient learning methods such as SRS to learn
        kanji that you don&apos;t know well first. The spaced repetition system
        will ask you to review kanji that you know less well.
      </div>
    </>,
    tags: ['general', 'srs'],
  }, {
    question: <>
      Where is Kuma Learn headed?
    </>,
    answer: <>
      Kuma Learn started with a <DictLink>picture dictionary</DictLink> as well as <Link to="/ebooks">eBooks</Link> but
      now also offers a <Link href="https://app.kumalearn.com/srs?utm_medium=web&utm_source=www&utm_campaign=faqs" className="underline text-gray-50">Spaced Repetition System (SRS)</Link> so that you can learn vocab and kanji efficiently.
      Soon, you&apos;ll have courses to add structure to your learning!
    </>,
    tags: ['general'],
  }, {
    question: <>
      Who&apos;s behind Kuma Learn?
    </>,
    answer: <>
      I&apos;m Mario, I speak three languages (English, Japanese, German)
      and make software for a living. I created Kuma Learn because
      I want to make learning the Japanese language easier and more fun!
      Reach out to me at <a className="underline text-gray-50" href="mailto:mario@kumalearn.com">mario@kumalearn.com</a> if you have any thoughts or suggestions.
    </>,
    tags: ['general'],
  },
];

const Faqs = ({ filter = [] }) => (
  <div className="bg-green-700">
    <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <h2 className="text-center text-3xl font-extrabold text-gray-100 sm:text-4xl">Frequently asked questions</h2>
      <dl className="mt-6 space-y-6 divide-y divide-gray-200">
        {faqs
          .filter((faq) => {
            if (filter.length === 0) {
            // Return all.
              return true;
            }
            const foundTag = faq.tags
              .reduce((acc, t) => acc || filter.includes(t), false);
            return foundTag;
          })
          .map((faq, faqIdx) => (
            <Disclosure as="div" key={`${faq.question}-${faqIdx}`} className="pt-6">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-100 focus:outline-none">
                      <span className="font-medium text-gray-50">{faq.question}</span>
                      <span className="ml-6 h-7 flex items-center">
                        <ChevronDownIcon
                          className={`${open ? '-rotate-180' : 'rotate-0'} h-6 w-6 transform`}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <p className="text-base text-gray-100">{faq.answer}</p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
      </dl>
    </div>
  </div>
);

export default Faqs;
